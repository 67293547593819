.appWrapper {
  display: grid;
  margin: 0 auto;
  width: 80%;
  grid-template-areas:
    "h"
    "c";
  grid-template-columns: auto;
  grid-template-rows: 5em auto;
}

.appWrapper__preloaderParent {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;
}

.appWrapper__preloaderBlock {
  display: block;
  border: none;
}