.header {
  grid-area: h;
  margin-bottom: 0.5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #073b4c;
  color: #fff;
  border-radius: 0 0 1.5em 1.5em;
}

.header__userAccountName {
  flex: 1 1 5%;
  text-align: left;
  font-family: "Montserrat";
  font-size: 12pt;
  padding: 1%;
}
.header__generalName {
  flex: 8 1 10%;
  text-align: center;
  font-family: "Montserrat";
  font-size: 20pt;
  font-weight: 900;
  padding-top: 1%;
  padding-right: 7%;
}

.header__telegramLogo {
  flex: 1 1 1%;
  text-align: center;
  padding-top: 1%;
}

.header__telegramLogo_image {
  width: 30pt;
  height: 30pt;
}
