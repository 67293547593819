.formControl {
}

.formControl.error input,
.formControl.error textarea {
  border: solid #ff2800 2px;
}

.formControl.error span {
  color: #ff2800;
}

.formSummaryError {
  border: solid #ff2800 1px;
  padding: 5px;
  color: #ff2800
}
